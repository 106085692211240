var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
export default function AutoplayPauseOnFocus(_a) {
    var on = _a.on;
    var focused = false;
    var hovered = false;
    on('afterInit', function (swiper) {
        var e_1, _a;
        if (!swiper.params.autoplay) {
            return;
        }
        swiper.el.addEventListener('mouseenter', function () {
            hovered = true;
            fixAutoplay(swiper);
        });
        swiper.el.addEventListener('mouseleave', function () {
            hovered = false;
            fixAutoplay(swiper);
        });
        try {
            for (var _b = __values(swiper.el.querySelectorAll('a, button')), _c = _b.next(); !_c.done; _c = _b.next()) {
                var el = _c.value;
                el.addEventListener('focus', function () {
                    focused = true;
                    fixAutoplay(swiper);
                });
                el.addEventListener('blur', function () {
                    focused = false;
                    fixAutoplay(swiper);
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
    function fixAutoplay(swiper) {
        if (!swiper.autoplay.running) {
            return;
        }
        if ((hovered || focused) && !swiper.autoplay.paused) {
            swiper.autoplay.pause();
        }
        else if (!(hovered || focused) && swiper.autoplay.paused) {
            swiper.autoplay.paused = false;
            swiper.autoplay.resume();
        }
    }
}
