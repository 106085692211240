var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
export default function FixTabIndex(_a) {
    var extendParams = _a.extendParams, on = _a.on;
    extendParams({
        watchSlidesProgress: true,
    });
    on('init', fixTabIndex);
    on('slideChangeTransitionStart', fixTabIndex);
    on('slideChange', fixTabIndex);
    function fixTabIndex(swiper) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        try {
            for (var _e = __values(swiper.wrapperEl.querySelectorAll('.swiper-slide-visible [tabindex="-1"]')), _f = _e.next(); !_f.done; _f = _e.next()) {
                var element = _f.value;
                element.tabIndex = undefined;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var elements;
        try {
            elements = swiper.wrapperEl.querySelectorAll('.swiper-slide:not(.swiper-slide-visible) :is(a, button, input, select, textarea, details):not([tabindex="-1"])');
            try {
                for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
                    var element = elements_1_1.value;
                    element.tabIndex = -1;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (elements_1_1 && !elements_1_1.done && (_b = elements_1.return)) _b.call(elements_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        catch (_g) {
            // Fix for old browsers (Safari)
            var slides = swiper.wrapperEl.querySelectorAll('.swiper-slide:not(.swiper-slide-visible)');
            try {
                for (var slides_1 = __values(slides), slides_1_1 = slides_1.next(); !slides_1_1.done; slides_1_1 = slides_1.next()) {
                    var slide = slides_1_1.value;
                    elements = slide.querySelectorAll('a, button, input, select, textarea, details');
                    try {
                        for (var elements_2 = (e_4 = void 0, __values(elements)), elements_2_1 = elements_2.next(); !elements_2_1.done; elements_2_1 = elements_2.next()) {
                            var element = elements_2_1.value;
                            if (element.tabIndex !== -1) {
                                element.tabIndex = -1;
                            }
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (elements_2_1 && !elements_2_1.done && (_d = elements_2.return)) _d.call(elements_2);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (slides_1_1 && !slides_1_1.done && (_c = slides_1.return)) _c.call(slides_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
    }
}
