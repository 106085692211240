import initSliders, { makeProdCarouselSwiper } from "./components/slider";
import { hidePreloader, showPreloader } from "./components/preloader";
import { getFetchOptions } from "./utils";
import initLazyLoad from "./components/lazy";
import { StatusClassNames } from "./types/StatusClassNames";
initSliders();
init();
function init() {
    var wrapper = document.getElementById('prod-carousel');
    var container = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.block__body');
    var tabs = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.tabs');
    if (!container || !wrapper || !tabs) {
        return;
    }
    tabs.querySelectorAll('.tabs__link').forEach(function (button) {
        button.addEventListener('click', function () {
            tabs.querySelectorAll(".tabs__link.".concat(StatusClassNames.active))
                .forEach(function (el) { return el.classList.remove(StatusClassNames.active); });
            button.classList.add(StatusClassNames.active);
            showPreloader(wrapper);
            fetch('/ajax/slider', getFetchOptions({
                type: button.dataset.type,
                elementId: wrapper.dataset.id,
            }, true))
                .then(function (response) { return response.text(); })
                .then(function (html) {
                var existingCarousel = document.getElementById('prod-swiper');
                if (existingCarousel) {
                    existingCarousel.swiper.destroy();
                }
                container.innerHTML = html;
                initLazyLoad();
                makeProdCarouselSwiper();
            })
                .finally(function () { return hidePreloader(true); });
        });
    });
}
